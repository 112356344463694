"use strict";

import "./js/inc/_jquery-global.js";
import "./js/inc/lite-yt-embed.js";
import "./scss/style.scss";

window.addEventListener("load", () => {
  // スマホメニュー表示・非表示
  const openBtn = document.querySelector(".js-navOpenBtn");
  const closeBtn = document.querySelector(".js-navCloseBtn");
  const headerNav = document.querySelector(".js-headerNav");
  const headerNavLogo = document.querySelector(".js-headerNavLogo");
  const headerNavLinks = document.querySelectorAll(".js-headerNavLink");
  const headerNavItems = document.querySelectorAll(".js-headerNavItem");
  const bodyElement = document.querySelector("body");
  const menuTL = gsap.timeline();

  openBtn.addEventListener("click", () => {
    menuTL
    .to(bodyElement, {
      onComplete: () => bodyElement.classList.toggle("is-open")
    })
    .to(headerNav, {
      onComplete: () => headerNav.classList.toggle("is-open")
    },'<')
    .fromTo(headerNav, {autoAlpha:0}, {autoAlpha:1,duration:.5,ease:'ease-in'}, '<')
    .fromTo(headerNavItems, {autoAlpha:0}, {autoAlpha:1,duration:.6,ease:'ease-in'})
    .fromTo(closeBtn, {autoAlpha:0}, {autoAlpha:1,duration:.5,ease:'ease-in'}, '<')
    .fromTo(headerNavLogo, {autoAlpha:0}, {autoAlpha:1,duration:.6,ease:'ease-in'}, '<')
  });

  closeBtn.addEventListener('click', () => {
    menuTL
    .to(bodyElement, {
      onComplete: () => bodyElement.classList.remove("is-open")
    })
    .to(headerNav, {
      onComplete: () => headerNav.classList.remove("is-open")
    },'<')
    .fromTo(headerNav, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
    .fromTo(headerNavItems, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
    .fromTo(closeBtn, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
    .fromTo(headerNavLogo, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
  });

  headerNavLinks.forEach((navLink) => {
    navLink.addEventListener("click", () => {
      menuTL
      .to(bodyElement, {
        onComplete: () => bodyElement.classList.remove("is-open")
      })
      .to(headerNav, {
        onComplete: () => headerNav.classList.remove("is-open")
      },'<')
      .fromTo(headerNav, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
      .fromTo(headerNavItems, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
      .fromTo(closeBtn, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
      .fromTo(headerNavLogo, {autoAlpha:1}, {autoAlpha:0,duration:.4,ease:'ease-in'}, '<')
    });
  });

  // TOPに戻る・アンカーリンク位置調整
  $(function(){
    $('a[href^="#"]').click(function() {
      let speed = 800;
      let type = 'swing';
      let href= $(this).attr("href");
      let target = $(href == "#" ? 'html' : href);
      let position = target.offset().top - 100;
      $('body,html').animate({scrollTop:position}, speed, type);
      return false;
    });
  });

  // TOP お客様の声 スライダー
  const voiceSlider = document.querySelector('.js-voiceSlider');
  if(voiceSlider) {
    const slideLength = document.querySelectorAll('.p-voice__slide').length;
    if(slideLength > 2) {
      const voiceSwiper = new Swiper('.js-voiceSlider', {
        loop: true,
        speed: 400,
        slidesPerView: 1.3,
        spaceBetween: 20,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          640: {
            spaceBetween: 30,
            slidesPerView: 2.5,
          },
          1280: {
            spaceBetween: 40,
            slidesPerView: 2.5,
          },
          1400: {
            spaceBetween: 60,
            slidesPerView: 3.3,
          },
          1500: {
            spaceBetween: 40,
            slidesPerView: 4.5,
          },
        },
      });
    } else {
      voiceSlider.classList.add('is-stop');
    }
  }

  const worksSlider = document.querySelector('.js-worksSlider');
  if(worksSlider) {
    const slideLength = document.querySelectorAll('.p-works__slide').length;
    if(slideLength > 2) {
      const worksSlider = new Swiper('.js-worksSlider', {
        loop: true,
        speed: 600,
        slidesPerView: 1.2,
        spaceBetween: 20,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          640: {
            spaceBetween: 30,
            slidesPerView: 1.66,
          },
          768: {
            spaceBetween: 30,
            slidesPerView: 1.66,
          },
          992: {
            spaceBetween: 40,
            slidesPerView: 1.66,
          },
          1400: {
            spaceBetween: 60,
            slidesPerView: 1.66,
          },
          1500: {
            spaceBetween: 40,
            slidesPerView: 2.6,
          },
        },
      });
    } else {
      worksSlider.classList.add('is-stop');
    }
  }

  const mvSlider = document.querySelector('.js-mvSlider');
  if(mvSlider) {
      const mvSlider = new Swiper('.js-mvSlider', {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          waitForTransition: false,
        },
        loop: true,
        speed: 2000,
        slidesPerView: 1,
        centeredSlides: true,
        effect: 'fade',
        breakpoints: {
          640: {
            spaceBetween: 30,
            slidesPerView: 1.66,
          },
          768: {
            spaceBetween: 30,
            slidesPerView: 1.66,
          },
          992: {
            spaceBetween: 40,
            slidesPerView: 1.66,
          },
          1400: {
            spaceBetween: 60,
            slidesPerView: 1.66,
          },
          1500: {
            spaceBetween: 40,
            slidesPerView: 2.6,
          },
        },
      });
    } else {
    }

  // よくある質問
  const faq = document.querySelector('.js-faq');
  $(".js-faqBox:first-of-type .js-faqAnswer").css("display", "block");
  $(".js-faqBox:first-of-type .js-faqAnswer").addClass("is-active");
  $(".js-faqBox:first-of-type .js-faqQuestion").addClass("is-active");

  if(faq){
    $(".js-faqQuestion").on("click", function () {
      $(".js-faqQuestion").not(this).next().slideUp(400);
      $(".js-faqQuestion").not(this).next().removeClass("is-active");
      $(".js-faqQuestion").not(this).removeClass("is-active");
      $(this).next().slideToggle(400);
      $(this).next().toggleClass("is-active");
      $(this).toggleClass("is-active");
    });
  }

  // プラン Caseタブ
  const tabs = document.querySelectorAll('.js-tab');
  const tabContents = document.querySelectorAll('.js-tabContents');
  if(tabs) {
    tabs.forEach(function(tabListItem) {
      tabListItem.addEventListener('click', function() {
        const index = Array.prototype.indexOf.call(tabs, this);
  
        tabs.forEach(function(tab) {
          tab.classList.remove('is-tab-active');
          tab.setAttribute("aria-selected", false);
        });
        this.classList.add('is-tab-active');
        this.setAttribute("aria-selected", true);
  
        tabContents.forEach(function(tabContent) {
          tabContent.classList.remove('is-tab-active');
          tabContent.setAttribute("hidden", true);
        });
        tabContents[index].classList.add('is-tab-active');
        tabContents[index].removeAttribute("hidden");
      });
    });
  }

  // フェードインアニメーション
  const fadeInItems = document.querySelectorAll('.fadeInAnime');
  if(fadeInItems) {
    gsap.utils.toArray(fadeInItems).forEach((fadeInItem) => {
      gsap.fromTo(fadeInItem, {autoAlpha:0}, {autoAlpha:1, duration:.8, delay:.1 ,scrollTrigger: {
        trigger: fadeInItem,
        start:'top 90%',
      }})
    });
  }
});

// VR動画ポップアップ
window.addEventListener('load', function() {
  if(!localStorage.getItem('disp_popup')) {
    const dispPopup = function() {
      localStorage.setItem('disp_popup', 'on');
      const body = document.querySelector('body');
      const bgPopup = document.querySelector('.js-popup');
      const popup = document.querySelector('.js-popupInner');
      const popupTitleClose = document.querySelector('.js-popupClose');
      body.classList.add('is-popupOpen');

      bgPopup.addEventListener('click', function() {
        closePopup();
      });
      popup.addEventListener('click', function(e) {
        e.stopPropagation();
      });
      popupTitleClose.addEventListener('click', function() {
        closePopup();
      });

      function closePopup() {
        body.classList.remove('is-popupOpen');
      }
    };
    setTimeout(dispPopup, 1500);
  }
}, false);

// header スクロール変化
window.addEventListener("scroll", function () {
  const sliderHeight = document.querySelector(".js-fv").offsetHeight;
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  if (sliderHeight - 30 < scrollTop) {
    document.querySelector(".js-header").classList.add("is-active");
  } else {
    document.querySelector(".js-header").classList.remove("is-active");
  }
});

//// TOPに戻るボタン スクロール変化
document.addEventListener('DOMContentLoaded', function () {
  const pageTopIcons = document.querySelectorAll('.js-pageTopIcon');
  const footer = document.querySelector('.js-footer');

  function checkPosition() {
      const footerRect = footer.getBoundingClientRect();
      const isActive = footerRect.top < window.innerHeight;

      pageTopIcons.forEach(icon => {
          if (isActive) {
              icon.classList.add('is-active');
          } else {
              icon.classList.remove('is-active');
          }
      });
  }

  window.addEventListener('scroll', checkPosition);
  checkPosition();
});


//// TOPに戻るボタン表示
window.addEventListener('scroll', () => {
  const pageTop = document.querySelector('.js-pageTop');
  const currentY = window.pageYOffset;

  if(currentY >= 200) {
    setTimeout(() => {
      pageTop.style.opacity = 1;
      pageTop.style.visibility = 'visible';
    }, 1);
  } else {
    setTimeout(() => {
      pageTop.style.opacity = 0;
      pageTop.style.visibility = 'hidden';
    }, 1);
  }
});